<template>
  <div mvp-stage-result>
    <h3>MVP</h3>
    <table>
      <thead>
        <tr>
          <th>팀명</th>
          <th>선수명</th>
          <th>보상</th>
        </tr>
        <tr class="margin">
          <th colspan="3" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>TNL Esports</td>
          <td>TS</td>
          <td>100만원</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'MvpStageResult',
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[mvp-stage-result] { .font-g;
  h3 { .wh(303, 44); .fs(22, 44px); .c(white); .tc; .mh-c; .mb(16); .bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=mvp.svg'); .no-repeat;}
  table { .mb(40);
    thead { .fs(11, 11px); .tc; .c(white); .medium; .bgc(#78766e);
      > tr {
        > th { .-r(#d2d0ca, 1px); .h(32);}
      }
      .margin {
        > th {.bgc(#d6d4d1); .h(6); border-right: none;}
      }
    }
    tbody { .w(100%); .bgc(#312f2a); .p(15, 20, 15, 63); .-box; .rel;
      > tr { .-b(#d2d0ca, 1px); .h(57);
        > td { .fs(14); .c(white); .bold; .mb(4); .-a(#d2d0ca, 1px); .tc;
          &:nth-of-type(2) { .regular; }
          &:nth-of-type(3) { .c(#ffc700);}
        }
      }
    }
  }
  @media (@tp-up) {
    h3 { .wh(100%, 52); .max-w(580); .lh(52); .mb(30); .bg('@{val-home}/leaderboard/subtitle/subtitle_type=mvp.svg'); .no-repeat; }
    table { .max-w(580); .mh-c;
      thead { .fs(14, 14px);
        .margin {
          > th { .h(12);}
        }
      }
      tbody {
        > tr { .h(60);
          > td {.fs(20, 20px);}
        }
      }
    }
  }
}

</style>
