<template>
  <div radiant-stage-result>
    <h3 v-if="qualifiers || stage || final ">레디언트 스테이지</h3>
    <div v-if="qualifiers" class="qualifiers">
      <p class="msg">{{ msg[0] }}</p>
      <table>
        <thead>
          <tr>
            <th>그룹</th>
            <th>팀명</th>
          </tr>
          <tr class="margin">
            <th colspan="3" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="2" class="group">A그룹</td>
            <td>FUG <em>VS</em> TNL</td>
          </tr>
          <tr>
            <td>I4-INS4NE <em>VS</em> 이사가자</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">B그룹</td>
            <td>LuZi <em>VS</em> WGS</td>

          </tr>
          <tr>
            <td>GGamDira <em>VS</em> Wyvern</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">C그룹</td>
            <td>Straight Warfare <em>VS</em> ASG</td>
          </tr>
          <tr>
            <td>KGA <em>VS</em> iCE Gunmandu</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">D그룹</td>
            <td>VVS_ <em>VS</em> 레.스.고</td>
          </tr>
          <tr>
            <td>I Need Girlfriend <em>VS</em> SPG</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="stage" class="group-stage">
      <p class="msg">{{ msg[1] }}</p>
      <table>
        <thead>
          <tr>
            <th>그룹</th>
            <th>8강 진출팀</th>
          </tr>
          <tr class="margin">
            <th colspan="3" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="2" class="group">A그룹</td>
            <td>이사가자</td>
          </tr>
          <tr>
            <td>TNL Esports</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">B그룹</td>
            <td>Wyvern</td>
          </tr>
          <tr>
            <td>WGS X-IT</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">C그룹</td>
            <td>ICE Gunmandu</td>
          </tr>
          <tr>
            <td>Alpha Six Gaming</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">D그룹</td>
            <td>Spear Gaming</td>
          </tr>
          <tr>
            <td>VVS_</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="final" class="final">
      <p class="msg">{{ msg[2] }}</p>
      <table>
        <thead>
          <tr>
            <th>순위</th>
            <th>팀명</th>
            <th>순위보상</th>
          </tr>
          <tr class="margin">
            <th colspan="3" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank1.svg" />
            </td>
            <td>TNL Esports</td>
            <td>1,300만원</td>
          </tr>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank2.svg" />
            </td>
            <td>VVS_</td>
            <td>600만원</td>
          </tr>
          <tr>
            <td rowspan="2">
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank3.svg" />
            </td>
            <td>이사가자</td>
            <td rowspan="2">각 400만원</td>
          </tr>
          <tr>
            <td>WGS X-IT</td>
          </tr>
          <tr>
            <td rowspan="4">
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank5.svg" />
            </td>
            <td>Alpha Six Gaming</td>
            <td rowspan="4">각 100만원</td>
          </tr>
          <tr>
            <td>Wyvern</td>
          </tr>
          <tr>
            <td>ICE Gunmandu</td>
          </tr>
          <tr>
            <td>Spear Gaming</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadiantStageResult',

  data() {
    return {
      msg: ['예선전 결과입니다', '그룹 스테이지 결과입니다', '본선 최종 결과입니다'],
    };
  },
  computed: {
    todayTimeStamp() {
      return new Date().getTime();
    },
    qualifiers() {
      return new Date('2021-10-29').getTime() <= this.todayTimeStamp && this.todayTimeStamp < new Date('2021-11-05').getTime();
    },
    stage() {
      return new Date('2021-11-05').getTime() <= this.todayTimeStamp && this.todayTimeStamp < new Date('2021-11-17').getTime();
    },
    final() {
      return this.todayTimeStamp >= new Date('2021-11-17').getTime();
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[radiant-stage-result] { .font-g;
  h3 { .wh(303, 44); .fs(22, 44px); .c(white); .tc; .mh-c; .bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=rad.svg'); .no-repeat; }
  .msg { .block; .mt(10); .mb(12); .fs(12); .c(#7c7869); .vat; .tc;
    &:before, &:after {.cnt; .ib; .fs(12); .wh(4, 4); .bgc(#7c7869); .t-r(45deg); .vam; .m(0, 4, 2);}
  }
  table { .mb(40);
    thead { .fs(11, 11px); .tc; .c(white); .medium; .bgc(#78766e);
      > tr {
        > th { .-r(#8c897f, 1px); .h(32);
          &:nth-of-type(1) { .w(20%);}
        }
      }
      .margin {
        > th { .bgc(#d6d4d1); .h(6); border-right: none;}
      }
    }
    tbody { .w(100%); .bgc(#312f2a); .p(15, 20, 15, 63); .-box; .rel;
      > tr { .-b(#d2d0ca, 1px); .h(57);
        > td { .fs(14); .c(white); .regular; .mb(4); .-a(#5a5854, 1px); .tc; .bold;
          > em { .regular;}
          > span {.ib; .mb(4);}
        }
        .group { .regular;}
      }
    }
  }
  .final {
    table {
      tbody {
        > tr {
          > td {
            &:nth-of-type(1) { .fs(12);}
            &:nth-of-type(3) { .c(#ffc700);}
          }
        }
      }
    }
  }

  @media (@tp-up) {
    h3 { .wh(100%, 52); .max-w(580); .lh(52); .bg('@{val-home}/leaderboard/subtitle/subtitle_type=rad.svg'); .no-repeat; }
    .msg { .mb(20);}
    table { .max-w(580); .mh-c; .mb(60);
      thead { .fs(14, 14px);
        .margin {
          > th { .h(12);}
        }
      }
      tbody {
        > tr {
          > td { .fs(20, 20px);
            > span { .mb(8);}
            &:nth-of-type(1) { .fs(20, 20px);}
          }
        }
      }
    }
    .final {
      table {
        tbody {
          > tr {
            > td { .h(60);
              &:nth-of-type(1) { .fs(20, 20px);}
              &:nth-of-type(3) { .c(#ffc700);}
            }
          }
        }
      }
    }
  }
}

</style>
