<template>
  <div gd-stage>
    <div gold-stage-result>
      <h3 class="gold">골드 스테이지</h3>
      <table>
        <thead>
          <tr>
            <th>순위</th>
            <th>팀명</th>
            <th>순위보상</th>
          </tr>
          <tr class="margin">
            <th colspan="3" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank1.svg" />
            </td>
            <td>LucidDream</td>
            <td>200만원</td>
          </tr>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/2.svg" />
            </td>
            <td>처음초롬</td>
            <td>100만원</td>
          </tr>
          <tr>
            <td rowspan="2">
              <img src="/img/pages/shotVal/home/leaderboard/rank/3.svg" />
            </td>
            <td>늪지대</td>
            <td rowspan="2">각 50만원</td>
          </tr>
          <tr>
            <td>Citizen</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div diamond-stage-result>
      <h3 class="diamond">다이아 스테이지</h3>
      <table>
        <thead>
          <tr>
            <th>순위</th>
            <th>팀명</th>
            <th>순위보상</th>
          </tr>
          <tr class="margin">
            <th colspan="3" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank1.svg" />
            </td>
            <td>Pokemon Masters</td>
            <td>250만원</td>
          </tr>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank2.svg" />
            </td>
            <td>NINAVE</td>
            <td>150만원</td>
          </tr>
          <tr>
            <td rowspan="2">
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank3.svg" />
            </td>
            <td>FTS gaming</td>
            <td rowspan="2">각 50만원</td>
          </tr>
          <tr>
            <td>Drama Fan</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoldDiaStageResult',

};

</script>

<style lang="less">
@import '~@/less/proj.less';

[gd-stage] { .font-g; .mh-c;
  h3 { .wh(303, 44); .fs(22, 44px); .c(white); .tc; .mh-c; .mb(16);}
  table { .mb(40);
    thead { .fs(11, 11px); .tc; .c(white); .medium; .bgc(#78766e); .-b(#d6d4d1, 6px);
      > tr {
        > th { .-r(#8c897f, 1px); .h(32);}
      }
      .margin {
        > th {.bgc(#d6d4d1); .h(6); border-right: none;}
      }
    }
    tbody { .w(100%); .bgc(#312f2a); .p(15, 20, 15, 63); .-box; .rel;
      > tr { .-b(#d2d0ca, 1px); .h(57);
        > td { .fs(14); .c(white); .bold; .mb(4); .-a(#5a5854, 1px); .tc;
          &:nth-of-type(3) { .c(#ffc700);}
        }
      }
    }
  }
  [gold-stage-result] {
    > h3 { .bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=gold.svg'); .no-repeat; }
  }
  [diamond-stage-result] {
    > h3 { .bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=dia.svg'); .no-repeat;}
  }

  @media (@tp-up) {
    h3 { .wh(100%, 52); .max-w(580); .mb(30); .lh(52); }
    table { .max-w(580); .mh-c; .mb(60);
      thead { .fs(14, 14px);
        .margin {
          > th { .h(12);}
        }
      }
      tbody {
        > tr { .h(60);
          > td {.fs(20, 20px);}
        }
      }
    }
    [gold-stage-result] {
      > h3 { .bg('@{val-home}/leaderboard/subtitle/subtitle_type=gold.svg'); .no-repeat; }
    }
    [diamond-stage-result] {
      > h3 { .bg('@{val-home}/leaderboard/subtitle/subtitle_type=dia.svg'); .no-repeat;}
    }
  }
}

</style>
