<template>
  <div valorant-leaderboard>
    <PSection class="leaderboard-section">
      <div class="inner-holder">
        <SectionTitle title-ko="대회 결과" black sm />
        <div class="list-holder" v-if="!empty">
          <GoldDiaStageResult />
          <RadiantStageResult />
          <MvpStageResult v-if="mvp" />
        </div>
        <div class="empty" v-else>
          대회 결과는 스테이지 종료 후 업데이트 됩니다.
        </div>
      </div>
    </PSection>
  </div>
</template>

<script>
import meta from '@/data/meta';
import GoldDiaStageResult from '@/views/components/leaderboard/showVal/GoldDiaStageResult.vue';
import RadiantStageResult from '@/views/components/leaderboard/showVal/RadiantStageResult.vue';
import MvpStageResult from '@/views/components/leaderboard/showVal/MvpStageResult.vue';
import SectionTitle from '@/views/components/common/SectionTitle.vue';

export default {
  name: 'ValorantLeaderboard',
  components: { SectionTitle, MvpStageResult, RadiantStageResult, GoldDiaStageResult },
  data() {
    return {};
  },
  computed: {
    empty() {
      const todayTimeStamp = new Date().getTime();
      const startTimeStamp = new Date('2021-10-29').getTime();

      return todayTimeStamp < startTimeStamp;
    },
    mvp() {
      const todayTimeStamp = new Date().getTime();
      return todayTimeStamp >= new Date('2021-11-18').getTime();
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },

};
</script>

<style lang="less">
@import '~@/less/proj.less';

[valorant-leaderboard] { .bgc(white);
  .inner-holder { .p(40, 20, 60);
    [section-title] {
      .ko {.fs(20, 20); .bold; .font-g; .ls(-1); .mb(20);}
    }
    .empty { .wh(100%, 600); .bgc(#ebebf0); .br(4); .items-center; .flex-middle; .mt(40); .c(rgba(0, 0, 0, .8));
      &:before { .cnt; .wh(60, 52); .contain('@{logo}/ico-riot-dark-gray.svg'); .mb(20);}
    }
    .list-holder { .p(40, 20, 10, 20); .bg('@{val-home}/leaderboard/leaderboard_bg/leaderboard_bg_1024.png'); .no-repeat;
      [shot-val-grade-table] { .mt(40);
        &:nth-of-type(1) { .mt(0); }
      }
    }
  }
  @media (@tp-up) {
    .w(710); .mh-c;
  }
  @media (@tl-up) {
    .w(966);
    .inner-holder { .p(60, 0);
      [section-title] {
        .ko { .fs(32, 32); .ml(0); .mb(40);}
      }
      .list-holder { .p(80, 30, 30, 30); .bg('@{val-home}/leaderboard/leaderboard_bg/leaderboard_bg_1920.png'); .no-repeat;
        [shot-val-grade-table] { .mt(60); }
      }
    }
  }
  @media (@ds-up) {
    .w(1200);
    .inner-holder { .p(60, 0, 120); }
  }
}
</style>
